import React, {useContext} from 'react';
import {makeStyles} from "@material-ui/core";
import Search from "../../../Search";
import CalendarOptions from "./CalendarOptions";
import DefaultOptionLabel from "../DefaultOptionLabel";
import CalendarOptionsHeaders from "../CalendarOptionsHeaders";
import {BarLoader} from "react-spinners";
import {AppContext} from "../../../Context/AppContext";

const CalendarFinder = () => {

    const state = useContext(AppContext);
    const {customContributionGraphIsLoading} = state;

    const containerClass = makeStyles({
        root: {
            display: 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'margin-bottom': '4rem',
        }
    });

    const instructionsClass = makeStyles({
        root: {
            display: 'flex',
            'align-items': 'center',
            width: '4rem'
        }
    });

    const dividerClass = makeStyles({
        root: {
            content: "",
            height: '10em',
            margin: '2% 4%',
            padding: '0.1rem',
            'background-color': 'black',
        }
    });

    const calendarSelectorClass = makeStyles({
       root: {
           display: 'flex',
           'flex-direction': 'column',
           'justify-content': 'center'
       }
    });

    // Values copied from the CustomCalendarOption component. Needs to keep the same pixel ratio.
    const calendarSpinnerContainerClass = makeStyles({
        spinner: {
        },
        hidden: {
            visibility: 'hidden'
        },
        '@media screen and (min-width:724px)': {
            spinner: {
                width: '550px'
            },
        },
        '@media screen and (min-width:1200px)': {
            spinner: {
                width: '400px'
            },
        },
    });

    const spinnerContainerClasses = calendarSpinnerContainerClass()

    return (
        <div className={containerClass().root}>
            <div className={instructionsClass().root}>
                <h3>Find Your GitHub Profile</h3>
            </div>
            <div className={dividerClass().root}></div>
            <div className={calendarSelectorClass().root}>
                <CalendarOptionsHeaders />
                <div className={spinnerContainerClasses.spinner + ' ' + (customContributionGraphIsLoading ? '' : spinnerContainerClasses.hidden)}>
                    <BarLoader height={4} width={'100%'} loading={true} color={"lightGreen"}/>
                </div>
                <CalendarOptions />
            </div>
        </div>
    );
};

export default CalendarFinder