import React from 'react';

interface optionProps {
    displayClass: string
}

const DefaultOptionLabel = (props: optionProps) => {
    return (
        <div className={props.displayClass}>
            <span>Or use our default calendar &#x1F447;</span>
        </div>
    );
};

export default DefaultOptionLabel