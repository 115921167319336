import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Button, TextField} from "@material-ui/core";

const Footer = () => {

    const styles = makeStyles({
       footerContainer: {
           display: 'flex',
           'flex-direction': 'row',
           'justify-content': 'space-between',
           'margin-left': '12%',
           'margin-right': '12%',
           'margin-top': '2rem',
       },
        trademarkContainer: {
           display: 'flex',
            'flex-direction': 'column'
        },
        emailContainer: {
           display: 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'justify-content': 'flex-end',
        },
        emailLabel: {
           'font-size': '0.5rem',
            width: '20%',
            'padding-right': '0.2rem'
        },
        emailTextInput: {
            'margin-right': '0.5rem'
        },
        logoFont: {
           'font-size': '2.25rem',
            'font-family': 'Bungee, cursive'
        }
    });
    const styleClasses = styles();

    /*Button type changed to 'button' from 'submit' to prevent page reload*/
    return (
        <div className={styleClasses.footerContainer}>
            <div className={styleClasses.trademarkContainer}>
                <span>My Git Mug LLC</span>
                <span>{logo(styleClasses.logoFont)}</span>
            </div>
            <div className={styleClasses.emailContainer}>
                <span className={styleClasses.emailLabel}>Sign up to receive product releases and announcements</span>
                <form id="mail-form">
                    <TextField className={styleClasses.emailTextInput} id="outlined-basic" label="Email" variant="outlined" size="small" />
                </form>
                <Button type="button" form="mail-form" variant="contained" color="primary">Sign Up</Button>
            </div>
        </div>
    );
};

const logo = (fontClass: any) => {
    return (
        <span className={fontClass}>My Git Mug</span>
    );
};

export default Footer;