import React, {Dispatch, Fragment, SetStateAction, useContext, useEffect, useState} from 'react'
import {AppContext} from "./Context/AppContext";
import {loadStripe} from '@stripe/stripe-js';
import {makeStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";
import {purchaseOptionSizes} from "./components/purchase/PurchaseOptions";
import config from "./config";

const PurchaseInformation: React.FC = () => {

    const [sessionId, setSessionId] = useState('')
    const [purchaseButtonEnabled, setPurchaseButtonEnabled] = useState(false);
    const stripe = loadStripe(config.stripeKey);
    const state = useContext(AppContext)
    const {lastSearchedUsername} = state;
    const {customContributionGraphDidLoad} = state;
    const {productSelectionState} = state;

    const requestUsername = () => {
        let letRequestedDefaultCalendar = productSelectionState.graphSelection === "default";
        let requestUsername = ""
        if(!letRequestedDefaultCalendar) {
            requestUsername = lastSearchedUsername
        }
        return requestUsername
    };

    const purchaseMug = async () => {
        console.log("purhcase!")
        // todo: validate that lastSearchedUsername is not null.
        let isPremium = productSelectionState.mugSelection === "premium";
        let sId = await fetchNewSessionId(requestUsername(), isPremium)
        setSessionId(sId);

        console.log("purchase mug clicked with username: " + lastSearchedUsername)
        console.log("redirecting with sessionId: " + sessionId)
        const s = await stripe
          await s?.redirectToCheckout({
            sessionId: sId
        })
    };

    // the centering container is used to center the purchase button between the purchase options
    const styles = makeStyles({
        fullWidthContainer: {
            width: '100%',
            display: 'flex',
            'justify-content': 'center'
        },
        container: {
            // matches the width of the other containers (product details and purchase flow)
            width: '1024px'
        },
        centeringContainer: {
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'margin-left': `calc(10.4rem)`
        },
        '@media screen and (min-width:724px)': {
            centeringContainer: {
                'width': `calc(5rem + ${calculateContainerWidth(purchaseOptionSizes.medium)}px)`,
            }
        },
        '@media screen and (min-width:1200px)': {
            centeringContainer: {
                'width': `calc(5rem + ${calculateContainerWidth(purchaseOptionSizes.large)}px)`,
            }
        },
        '@media screen and (max-width: 724px)': {
            centeringContainer: {
                'width': `${purchaseOptionSizes.medium}`,
            }
        },
        purchaseButton: {
            'margin-top': '1.5rem'
        }
    });

    const styleClasses = styles();

    useEffect(() => {
        if(productSelectionState.graphSelection != null && productSelectionState.mugSelection != null) {
            setPurchaseButtonEnabled(true);
        }
    }, [productSelectionState]);

    return (
        <Fragment>
            <div className={styleClasses.fullWidthContainer}>
                <div className={styleClasses.container}>
                    <div className={styleClasses.centeringContainer}>
                        <Button className={styleClasses.purchaseButton} variant="contained" color="primary" size="large" disabled={!purchaseButtonEnabled} onClick={purchaseMug}>Purchase</Button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

/**
 * Helper function for css calc that just multplies the width by 2 (for each purchase option)
 * @param purchaseOptionWidth
 */
function calculateContainerWidth(purchaseOptionWidth: number): number {
    return (purchaseOptionWidth * 2)
}

async function fetchNewSessionId(username: string, isPremium: boolean): Promise<string> {
    // const resp = await fetch(`http://localhost:8080/mug/session/id?username=${encodeURI(username)}`);
    // const resp = await fetch(`https://api.mygitmug.com:80/mug/session/id?username=${encodeURI(username)}&isPremium=${encodeURI(isPremium.toString())}`);
    const resp = await fetch(`${config.baseEndpoint}/mug/session/id?username=${encodeURI(username)}&isPremium=${encodeURI(isPremium.toString())}`);
    const json = await resp.json();
    return json.sessionId
}

export default PurchaseInformation