import React, {Fragment, useContext, useEffect, useState} from 'react';
import DefaultCalendarOption from "./DefaultCalendarOption";
import CustomCalendarOption from "./CustomCalendarOption";
import {makeStyles} from "@material-ui/core/styles";
import {AppContext} from "../../../Context/AppContext";
import DefaultOptionLabel from "../DefaultOptionLabel";

export interface CalendarOptionProps {
    isSelected: Boolean,
    onClickBehavior: () => void
}

const CalendarOptions: React.FC = () => {

    const appContext = useContext(AppContext);
    const {productSelectionState, setProductSelectionState} = appContext;
    const {customContributionGraphDidLoad} = appContext;

    const [customCalendarIsSelected, setCustomCalendarIsSelected] = useState(false);
    const [defaultCalendarIsSelected, setDefaultCalendarIsSelected] = useState(false);
    const [showingErrorMessage, setShowingErrorMessage] = useState(false);

    const customCalendarWasClicked = () => {
        if(!customCalendarIsSelected && customContributionGraphDidLoad) {
            setCustomCalendarIsSelected(true);
            setDefaultCalendarIsSelected(false);
            setProductSelectionState({...productSelectionState, graphSelection: "custom"})
        } else if(!customContributionGraphDidLoad) {
            // show error message and bounce the search bar
            console.log("showing error message")
            setShowingErrorMessage(true);
        }
    };

    const defaultCalendarWasClicked = () => {
        if(!defaultCalendarIsSelected) {
            setDefaultCalendarIsSelected(true);
            setCustomCalendarIsSelected(false);
            setProductSelectionState({...productSelectionState, graphSelection: "default"})
        }
    };

    useEffect(() => {
        setShowingErrorMessage(false);
    }, [customContributionGraphDidLoad]);

    const calendarOptionsClasses = makeStyles({
        root: {
            display: 'flex',
            'flex-direction': 'row'
        },
        '@media screen and (max-width: 724px)': {
            root: {
                'flex-direction': 'column'
            }
        },
        '@media screen and (min-width:724px)': {
            root: {
                'flex-direction': 'column'
            }
        },
        '@media screen and (min-width:1200px)': {
            root: {
                'flex-direction': 'row'
            },
        },
        calendar: {
            'margin-right': '5rem'
        }
    });

    const styles = makeStyles({
        errorLabel: {
            color: 'red',
            'display': 'none'
        },
        displayIt: {
            'display': 'inline-block'
        }
    });
    const styleClasses = styles();

    return (
        <Fragment>
            <div className={calendarOptionsClasses().root}>
                <CustomCalendarOption {...{isSelected: customCalendarIsSelected, onClickBehavior: customCalendarWasClicked}}/>
                <DefaultOptionLabel {...{displayClass: "default-option-label-container_medium_small_display"}}/>
                <DefaultCalendarOption {...{isSelected: defaultCalendarIsSelected, onClickBehavior: defaultCalendarWasClicked}} />
            </div>
            <label className={styleClasses.errorLabel + ' ' + (showingErrorMessage ? styleClasses.displayIt : '')}>
                You need to search for a github profile, first
            </label>
        </Fragment>
    );
};

export default CalendarOptions;