import React from 'react';
import {makeStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {PurchaseOptionProp} from "./PurchaseOptions";

const PurchaseOption = (props: PurchaseOptionProp) => {
        const baseStyles = makeStyles({
            ...props.sharedStyle,
            root: {
                'margin-right': '2.5rem',
                '&:hover': {
                    border: 'solid black 1px'
                }
            },
        });
    const baseStyle = baseStyles();

    return (
        <Button className={baseStyle.baseStyle + ' ' + baseStyle.root + ' ' + (props.isSelected ? baseStyle.selectedStyle : '')} onClick={props.onClickBehavior}>
            <div className="purchase-option-button-container">
                <span> Ceramic Mug 11oz </span>
                <img className="product-option-image product-option-image_default_mug" src="./default_handle_left_cropped.png"/>
                <span> $20 </span>
                <span className="product-option_free_shipping"> FREE SHIPPING </span>
            </div>
        </Button>
    );
};

export default PurchaseOption;