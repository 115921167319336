import React, {Fragment} from 'react';
import Search from "../../Search";
import DefaultOptionLabel from "./DefaultOptionLabel";

const CalendarOptionsHeaders = () => {

    return (
        <Fragment>
            <div className="calendar-options-headers-container">
                <Search />
                <DefaultOptionLabel {...{displayClass: "default-option-label-container_large_display"}}/>
            </div>
        </Fragment>
    );
};

export default CalendarOptionsHeaders;