import React from 'react';
import {makeStyles} from "@material-ui/core";
import PurchaseOptions from "./PurchaseOptions";

const MugSelection = () => {
    const styles = makeStyles({
        container: {
            display: 'flex',
            'flex-direction': 'row',
            'align-items': 'center'
        },
        instructions: {
            display: 'flex',
            'align-items': 'center',
            width: '4rem',
        },
        divider: {
            content: "",
            height: '10em',
            margin: '2% 4%',
            padding: '0.1rem',
            'background-color': 'black',
        },
        purchaseOptionsContainer: {
            flex: '1',
            display: 'flex',
        }
    });
    const styleClasses = styles();

    return (
        <div className={styleClasses.container}>
            <div className={styleClasses.instructions}>
                <h3>Choose Your Mug</h3>
            </div>
            <div className={styleClasses.divider}></div>
            <div className={styleClasses.purchaseOptionsContainer}>
                <PurchaseOptions />
            </div>
        </div>
    );
};

export default MugSelection