import React, { Fragment } from 'react';
import ProductDetail from './components/product/ProductDetail'
import CustomizeFlow from "./components/CustomizeFlow";
import PurchaseInformation from "./PurchaseInformation";

const MainPage: React.FC = () => {
    return(
        <Fragment>
	        <ProductDetail />
	        <CustomizeFlow />
            <PurchaseInformation />
        </Fragment>
    )
}

export default MainPage
