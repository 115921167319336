interface Config {
    baseEndpoint: string;
    stripeKey: string;
}

const developmentConfig: Config = {
    baseEndpoint: 'http://localhost:8080',
    stripeKey: 'pk_test_rbSkqbw85HggWpfblXeddwUo00G1CeL7Qr'
}

const productionConfig: Config = {
    baseEndpoint: 'https://api.mygitmug.com:80',
    stripeKey: 'pk_live_S0PHQs7hwkt6b9SePWYAXMkl00sSzxyTX5'
}

let config: Config;

switch (process.env.REACT_APP_ENV) {
    case 'production':
        config = productionConfig;
        break;
    case 'staging':
        config = developmentConfig;
        break;
    case 'development':
        config = developmentConfig;
        break;
    default:
        config = developmentConfig;
}

export default config;