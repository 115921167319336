import React from 'react';
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {CalendarOptionProps} from "./CalendarOptions";

const DefaultCalendarOption = (props: CalendarOptionProps) => {

    const style = makeStyles({
        button: {
            // width: '35rem',
            // height: '6.2rem',
            'border': '1px solid #d6d6d6',
            '&:hover': {
                border: 'solid black 1px'
            }
        },
        '@media screen and (max-width: 724px)': {
            button: {
                'margin-left': '0px'
            }
        },
        '@media screen and (min-width:724px)': {
            button: {
                'margin-left': '0px',
                width: '550px'
            }
        },
        '@media screen and (min-width:1200px)': {
            button: {
                'margin-left': '2.5rem',
                width: '400px'
            }
        },
        selected: {
            border: '.11765rem solid #0070c9 !important'
        },
        img: {
            width: '100%'
        }
    });
    const styleClasses = style();

    return (
            <Button className={styleClasses.button + ' ' + (props.isSelected ? styleClasses.selected : '')} onClick={props.onClickBehavior}>
                <img className={styleClasses.img} src="./new-default-nousername.png"/>
            </Button>
    );
};

export default DefaultCalendarOption;