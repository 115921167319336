import React, {Dispatch, Fragment, SetStateAction, useContext, useState} from 'react'
import {AppContext} from "./Context/AppContext";
import {Button} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import config from "./config";

const Search: React.FC = () => {

    // const appContext = useContext(AppContext) as AppContextInterface;
    const appContext = useContext(AppContext);
    const {username, setUsername} = appContext;
    const {lastSearchedUsername, setLastSearchedUsername} = appContext;
    // const {displayedUsername, setDisplayedUsername} = appContext
    const {customContributionGraphUrl, setCustomContributionGraphUrl} = appContext;
    const {customContributionGraphDidLoad} = appContext;
    const {setCustomContributionGraphIsLoading} = appContext;
    const [showMissingProfileAlert, setShowMissingProfileAlert] = useState<Boolean>(false)

    return (
        <Fragment>
            {/*<p>Search for your Github user</p>*/}
            <div className="search-box-container">
                { showMissingProfileAlert && <Alert severity={"warning"}>Could not find public GitHub profile for username "{lastSearchedUsername}"</Alert> }
                <form className="search-box" onSubmit={e => {
                    e.preventDefault();
                    formSubmit(username, setLastSearchedUsername, setCustomContributionGraphUrl, setCustomContributionGraphIsLoading, setShowMissingProfileAlert);
                }}>
                    <input
                        className="search-box-input"
                        type="text"
                        placeholder="Your Github username"
                        value={username}
                        onChange={(e) => {setUsername(e.target.value)}}/>
                    <Button type="submit" variant="contained" color="primary" size={"medium"}>
                        Search
                    </Button>
                </form>
                {/*<PulseLoader loading={isLoading as boolean}/>*/}
                { (lastSearchedUsername != "") && customContributionGraphDidLoad && !showMissingProfileAlert && <h3>Showing contributions graph for "{lastSearchedUsername}"</h3> }
            </div>
        </Fragment>
    )
};

const formSubmit = (
    username: String,
    setDisplayUsername: Dispatch<SetStateAction<String>>,
    setCustomContributionGraphUrl: Dispatch<SetStateAction<String>>,
    setIsLoading: Dispatch<SetStateAction<Boolean>>,
    setMissingProfileAlert: Dispatch<SetStateAction<Boolean>>
    ) => {
    setIsLoading(true)
    setDisplayUsername(username)
    console.log('searching for username: ' + username)
    setMissingProfileAlert(false)
    updatecustomContributionGraphUrl(username, setCustomContributionGraphUrl, setMissingProfileAlert, setIsLoading)
};

const updatecustomContributionGraphUrl = (
    username: String,
    setCustomContributionGraphUrl: Dispatch<SetStateAction<String>>,
    setMissingProfileAlert: Dispatch<SetStateAction<Boolean>>,
    setIsLoading: Dispatch<SetStateAction<Boolean>>
) => {
    // todo: enable when backend is up.
    // todo; add timeout
    // fetch("localhost:8080/contributions/" + username)
    // fetch("https://api.mygitmug.com:80/contributions/" + username)
    fetch(`${config.baseEndpoint}/contributions/${username}`)
        .then(res => res.json())
        .then(
            (result) => {
                setCustomContributionGraphUrl(result.contributionsImageStaticUrl)
            },
            (error) => {
                setMissingProfileAlert(true)
                console.log("There was an error fetching graph url: " + error)
            }
        )
        .finally(() => {
                setIsLoading(false)
        })
    // setCustomContributionGraphUrl("https://picsum.photos/800/150")
};

export default Search