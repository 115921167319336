import React, {Dispatch, Fragment, SetStateAction, useContext, useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";
import {AppContext} from "../../../Context/AppContext";
import {CalendarOptionProps} from "./CalendarOptions";
import {BarLoader, GridLoader} from "react-spinners";

const CustomCalendarOption = (props: CalendarOptionProps) => {

    const state = useContext(AppContext);
    const {customContributionGraphUrl} = state;
    const {customContributionGraphDidLoad, setCustomContributionGraphDidLoad} = state;
    const style = makeStyles({
        button: {
            // width: '35rem',
            // height: '6.2rem',
            'border': '1px solid #d6d6d6',
            '&:hover': {
                border: 'solid black 1px'
            }
        },
        '@media screen and (max-width: 724px)': {
            button: {
                'margin-right': '0px',
                'margin-bottom': '1rem'
            },
            hintSpan: {
                'font-size': '0.2em',
            }
        },
        '@media screen and (min-width:724px)': {
            button: {
                'margin-right': '0px',
                'margin-bottom': '1rem',
                width: '550px'
            },
            hintSpan: {
                'font-size': '1em'
            }
        },
        '@media screen and (min-width:1200px)': {
            button: {
                'margin-bottom': '0rem',
                'margin-right': '2.5rem',
                'padding': '0rem',
                width: '400px'
            },
            hintSpan: {
                'font-size': '1em'
            }
        },
        selected: {
            border: 'solid #0070c9 .11765rem !important'
        },
        image: {
            'width': '100%'
        },
        imageBlur: {
            'width': '100%',
            filter: 'blur(3px)'
        },
        hintSpan: {
            position: 'absolute'
        },
        hidden: {
            visibility: 'hidden'
        },
    });
    const styleClasses = style();

    const contributionDidLoad = () => {
        setCustomContributionGraphDidLoad(true);
    };

    return (
        <Fragment>
            <Button
                className={styleClasses.button + ' ' + (props.isSelected ? styleClasses.selected : '')}
                onClick={props.onClickBehavior}
                // disabled={!customContributionGraphDidLoad}
            >
                {customCalendarOptionBackgroundImage(customContributionGraphDidLoad, customContributionGraphUrl, contributionDidLoad, styleClasses)}
                <span className={styleClasses.hintSpan + ' ' + (customContributionGraphDidLoad ? styleClasses.hidden : '')}>Search for your GitHub username to select this option</span>
            </Button>
        </Fragment>
    );
};

function customCalendarOptionBackgroundImage(customContributionGraphDidLoad: boolean, customContributionGraphUrl: string, didLoadCallback: () => void, styleClasses: any): JSX.Element {
    if(customContributionGraphUrl) {
        return <img className={customContributionGraphDidLoad ? styleClasses.image : styleClasses.imageBlur} src={customContributionGraphUrl} onLoad={didLoadCallback}/>
    } else {
        return (<img className={styleClasses.imageBlur} src="./new-default-nousername.png" />)
    }
}

export default CustomCalendarOption;