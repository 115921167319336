import React, {Fragment} from 'react';

const ProductDetail : React.FC = () => {
	
	return (
		<Fragment>
		<div className="product-detail-container_full-width">
			<div className="product-detail-container">
				<div className="product-detail-container-upper">
					<div className="product-detail_message-upper">
						<span>Customize with your own GitHub contributions</span>
					</div>
					<div className="product-detail_mug_mock-upper">
						<img className="product-detail_image_default" src="./default_handle_right_cropped.png" />
					</div>
				</div>
				<div className="product-detail-container-lower">
					<div className="product-detail_mug_mock-lower">
						<img className="product-detail_image_premium" src="./premium_handle_left_cropped.png" />
					</div>
					<div className="product-detail_message-lower">
						<span>Add your handle</span>
					</div>
				</div>
			</div>
		</div>
		</Fragment>
	);

};

export default ProductDetail;
