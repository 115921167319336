import React, {Fragment, useContext, useState} from 'react';
import PurchaseOption from "./PurchaseOption";
import PremiumPurchaseOption from "./PremiumPurchaseOption";
import {AppContext} from "../../Context/AppContext";

export interface PurchaseOptionProp {
    sharedStyle: {
        baseStyle: any,
        selectedStyle: any
    }
    isSelected: Boolean,
    onClickBehavior: () => void
}

// units in px
export const purchaseOptionSizes = {
    medium: 300,
    large: 400
};

const PurchaseOptions = () => {

    const appContext = useContext(AppContext);
    const [premiumPurchaseOptionIsSelected, setPremiumPurchaseOptionIsSelected] = useState(false);
    const [basePurchaseOptionIsSelected, setBasePurchaseOptionIsSelected] = useState(false);
    const {productSelectionState, setProductSelectionState} = appContext;

    const premiumPurchaseOptionWasSelected = () => {
        if(!premiumPurchaseOptionIsSelected) {
            setPremiumPurchaseOptionIsSelected(true);
            setBasePurchaseOptionIsSelected(false);
            setProductSelectionState({...productSelectionState, mugSelection: "premium"})
        }
    };

    const basePurchaseOptionsWasSelected = () => {
        if(!basePurchaseOptionIsSelected) {
            setBasePurchaseOptionIsSelected(true);
            setPremiumPurchaseOptionIsSelected(false);
            setProductSelectionState({...productSelectionState, mugSelection: "base"})
        }
    };

    const purchaseOptionStyle = {
        sharedStyle: {
            baseStyle: {
                height: '20rem',
                border: '1px solid #d6d6d6',
                // border: '0.5em solid #b3b6bd'
                // 'border-radius': '2.5rem'
            },
            selectedStyle: {
                border: '.11765rem solid #0070c9 !important',
            },
            '@media screen and (max-width: 724px)': {
                baseStyle: {
                    'margin-right': '0px !important',
                    'margin-left': '0px !important',
                    'margin-bottom': '1rem',
                    height: '15rem',
                    width: '250px'
                }
            },
            '@media screen and (min-width:724px)': {
                baseStyle: {
                    width: '250px'
                }
            },
            '@media screen and (min-width:1200px)': {
                baseStyle: {
                    width: '400px'
                }
            }
        }
    };

    const basePurchaseOptionState = {
        isSelected: basePurchaseOptionIsSelected,
        onClickBehavior: basePurchaseOptionsWasSelected
    };

    const premiumPurchaseOptionState = {
        isSelected: premiumPurchaseOptionIsSelected,
        onClickBehavior: premiumPurchaseOptionWasSelected
    };

    return (
        <div className="purchase-options-container">
            <PurchaseOption {...{...purchaseOptionStyle, ...basePurchaseOptionState}} />
            <PremiumPurchaseOption {...{...purchaseOptionStyle, ...premiumPurchaseOptionState}} />
        </div>
    );
};

export default PurchaseOptions;