import React, { useEffect, useState, Fragment } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";
import { useHistory } from 'react-router-dom'
import Header from "../../Header";
import {GridLoader} from "react-spinners";
import config from "../../config";

interface ThankYouPageParams {
    orderId: string
}

interface LineItem {
    itemName: string;
    itemQuantity: number;
}

interface OrderSummary {
    orderId: string;
    itemsOrdered: LineItem[];
    orderTotal: string;
    shippingEstimate: string | undefined | null;
}

export const ThanksPage = (props: ThankYouPageParams) => {

    const styles = makeStyles({
        centeringContainer: {
            display: 'flex',
            'justify-content': 'center',
            'width': '100%'
        },
        summaryContainer: {
            display: 'grid',
            // 'grid-template-rows': '100px',
            'grid-template-columns': '100%',
            'grid-template-rows': '2fr repeat(5, 1fr)',
            'grid-auto-flow': 'column',
            'border': '1px solid black',
            'width': '50%',
            'padding-left': '16px',
            'margin-top': '20px'
        },
        thanksContainer: {

        },
        confirmationContainer: {

        },
        thankYouText: {
            'font-size': '2rem',
            'font-weight': 'bold',
            'display': 'flex',
            'justify-content': 'center',
            'padding-top': '16px',
        },
        continueButtonContainer: {
            'display': 'flex',
            'justify-content': 'center',
            'padding-bottom': '16px'
        },
        underlined: {
            'text-decoration': 'underline'
        },
        lineItem: {
            'padding-left': '4rem'
        },
        loadingSpinnerContainer: {
            display: 'flex',
            'justify-content': 'center',
            'width': '100%',
            'margin-top': '3rem'
        }
    });
    const styleClasses = styles();

    const history = useHistory();
    const [orderSummary, setOrderSummary] = useState();

    useEffect(() => {
        fetch(`${config.baseEndpoint}/order/summary/${props.orderId}`)
            .then(res => res.json())
            .then(
                (result) => { setOrderSummary(result) },
                (error) => { console.log("There was an error fetching the order summary: " + error) }
            )
            // .then((resp) => {
            //    setOrderSummary(resp.body);
            // });

        // setOrderSummary({
        //     orderId: 'ABC123',
        //     itemsOrdered: [{
        //         itemName: 'Premium Git Mug',
        //         itemQuantity: 1
        //     } as LineItem],
        //     orderTotal: "25.00",
        //     shippingEstimate: undefined
        // } as OrderSummary)
    }, []); // only run once

    /*
    TODO:
     - Show products ordered
     - Show order total
     - Show shipping estimate if it is provided.
     - Spinner while waiting instad of "bad" text
     - Add button to return to home page
     - Add a header
    */

    function displayLineItems() {
        return orderSummary.itemsOrdered.map((item: LineItem) => {
            return (
                <Fragment>
                <div className={styleClasses.underlined}>Ordered Items</div>
                <div className={styleClasses.lineItem}>
                    {item.itemQuantity}x {item.itemName}
                </div>
                </Fragment>
            )
        });
    }

    function renderSummary() {
        if(orderSummary) {
            return (
                <div className={styleClasses.centeringContainer}>
                    <div className={styleClasses.summaryContainer}>
                        <div className={styleClasses.thankYouText}>Thank you for your order!</div>
                        <div>Order Confirmation:  #{orderSummary.orderId}</div>
                        { orderSummary && displayLineItems() }
                        <div>Order total: {orderSummary.orderTotal}</div>
                        {orderSummary.deliveryEstimate && <div>Estimated delivery in {orderSummary.deliveryEstimate}</div>}
                        <div className={styleClasses.continueButtonContainer}>
                            <Button onClick={ () => { history.push("/") } } variant="contained" color="primary" size="medium" >Continue</Button>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={styleClasses.loadingSpinnerContainer}>
                    <GridLoader size={"20px"} color={"lightGreen"} loading={orderSummary}/>
                </div>
            )
        }
    };

    return (
        <Fragment>
            <Header />

            { renderSummary() }
        </Fragment>
    )
}