import React, {Fragment, useEffect} from 'react';
import {HashRouter, Route} from 'react-router-dom';
import './App.css';
import MainPage from "./MainPage";
import Header from "./Header";
import {AppContextProvider} from "./Context/AppContext";
import Footer from "./components/footer/Footer";
import {ThanksPage} from "./components/summary/ThanksPage";

function App() {

    // set page title
    useEffect(() => {
        document.title = "My Git Mug"
    }, []);

  return (
      <AppContextProvider>
        <HashRouter>
            <Route path="/summary/:id" exact={true} render={(props) => <ThanksPage orderId={props.match.params.id} />}  />
            <Route path="/" exact={true}>
                <Header />
                <MainPage />
                <Footer />
            </Route>
        </HashRouter>
      </AppContextProvider>
  );
}

export default App;
