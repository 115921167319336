import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const CustomizeHeadline = () => {

    const style = makeStyles({
        header: {
            display: 'flex',
            'justify-content': 'center',
        },
        '@media screen and (max-width: 724px)': {
            header: {
                'font-size': '2rem'
            }
        },
        '@media screen and (min-width:724px)': {
            header: {
                'font-size': '2.5rem'
            }
        },
        '@media screen and (min-width:1200px)': {
            header: {
                'font-size': '3rem'
            }
        },
    });
    const styleClasses = style();

    return(
        <h2 className={styleClasses.header}>Customize your own</h2>
    );
};

export default CustomizeHeadline;