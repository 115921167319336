import React, {Fragment} from 'react'
import CalendarFinder from "./purchase/finder/CalendarFinder";
import MugSelection from "./purchase/MugSelection";
import CustomizeHeadline from "./purchase/CustomizeHeadline";
import {makeStyles} from "@material-ui/core/styles";

const CustomizeFlow = () => {

    const style = makeStyles({
        fullWidthContainer: {
            display: 'flex',
            'justify-content': 'center',
        },
        container: {
            display: 'flex',
            'flex-direction': 'column',
        },
        '@media screen and (max-width: 724px)': {
            container: {
                'width': '100%'
            }
        },
        '@media screen and (min-width:724px)': {
            container: {
                width: '700px'
            }
        },
        '@media screen and (min-width:1200px)': {
            container: {
                'width': '1024px'
            }
        },
    });
    const styleClasses = style();

    return (
        <div className={styleClasses.fullWidthContainer}>
            <div className={styleClasses.container}>
                <CustomizeHeadline />
                <CalendarFinder />
                <MugSelection />
            </div>
        </div>
    );
};

export default CustomizeFlow