import React from 'react';
import {makeStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {PurchaseOptionProp} from "./PurchaseOptions";

const PremiumPurchaseOption = (props: PurchaseOptionProp) => {

    const premiumStyle = makeStyles({
        ...props.sharedStyle,
        root: {
            // this is the css for the cool css border -- don't delete
            // 'background-image': 'linear-gradient(white, white), radial-gradient(circle at top left, #00e461, #fffe06)',
            // 'background-origin': 'border-box',
            // 'background-clip': 'content-box, border-box',
            // 'padding': '2px',
            'margin-left': '2.5rem',
            '&:hover': {
                border: 'solid black 1px'
            }
        }
    });
    const premiumStyles = premiumStyle();

    return (
        <Button
            className={premiumStyles.baseStyle + ' ' + premiumStyles.root + ' ' + (props.isSelected ? premiumStyles.selectedStyle : '')}
            onClick={props.onClickBehavior}
        >
            <div className="purchase-option-button-container">
                <span> Premium Ceramic Mug 11oz </span>
                <img className="product-option-image" src="./premium_handle_left_cropped.png"/>
                <span> $25 </span>
                <span className="product-option_free_shipping"> FREE SHIPPING </span>
            </div>
        </Button>
    );
};

export default PremiumPurchaseOption;
