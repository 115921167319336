import React, {createContext, Dispatch, SetStateAction, useState} from "react";
import {ProductSelection} from "./ProductSelectionState";

export interface AppContextInterface {
    username: string,
    setUsername: Dispatch<SetStateAction<string>>,
    lastSearchedUsername: string,
    setLastSearchedUsername: Dispatch<SetStateAction<string>>,
    customContributionGraphUrl: string,
    setCustomContributionGraphUrl: Dispatch<SetStateAction<string>>,
    customContributionGraphDidLoad: Boolean,
    setCustomContributionGraphDidLoad: Dispatch<SetStateAction<Boolean>>,
    customContributionGraphIsLoading: Boolean,
    setCustomContributionGraphIsLoading: Dispatch<SetStateAction<Boolean>>,
    productSelectionState: ProductSelection,
    setProductSelectionState: Dispatch<SetStateAction<ProductSelection>>
}

// export const AppContext = createContext<AppContextInterface | null>(null);
export const AppContext = createContext<any>(null);

export const AppContextProvider = (props: any) => {
    // Initial values are obtained from the props
    const { children } = props;

    // Use State to keep the values
    const [username, setUsername] = useState('');
    const [lastSearchedUsername, setLastSearchedUsername] = useState('');
    const [customContributionGraphUrl, setCustomContributionGraphUrl] = useState('');
    const [customContributionGraphDidLoad, setCustomContributionGraphDidLoad] = useState('');
    const [customContributionGraphIsLoading, setCustomContributionGraphIsLoading] = useState(false);
    const [productSelectionState, setProductSelectionState] = useState({graphSelection: null, mugSelection: null});

    // Make the context object:
    const usersContext = {
        username,
        setUsername,
        lastSearchedUsername,
        setLastSearchedUsername,
        customContributionGraphUrl,
        setCustomContributionGraphUrl,
        customContributionGraphDidLoad,
        setCustomContributionGraphDidLoad,
        customContributionGraphIsLoading,
        setCustomContributionGraphIsLoading,
        productSelectionState,
        setProductSelectionState
    };

    // pass the value in provider and return
    return <AppContext.Provider value={usersContext}>{children}</AppContext.Provider>;
}